import React from 'react'
import { defaultTheme } from '@bonliva-ui/core/theme'
import { ThemeProvider } from 'styled-components'
import { BrowserRouter } from 'react-router-dom'
import { GlobalStyles } from '@bonliva-ui/core/global'
import Routing from '@bonliva-routes/web'
import { AuthProvider } from '@bonliva-auth/context'
import { PlatformType } from '@bonliva-auth/api'
import { MeetingProvider } from '@bonliva-traits/meeting-web'
import { preloadScript } from 'opentok-react'
import { WebdocNotesProvider } from '@bonliva-traits/webdoc-notes'

const App: React.FC = () => {
  return (
    <AuthProvider platformType={PlatformType.Web}>
      <ThemeProvider theme={defaultTheme}>
        <BrowserRouter>
          <WebdocNotesProvider>
            <MeetingProvider>
              <GlobalStyles />
              <Routing />
            </MeetingProvider>
          </WebdocNotesProvider>
        </BrowserRouter>
      </ThemeProvider>
    </AuthProvider>
  )
}

export default preloadScript(App)
